* {
    background-color: #2C2C2C;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
}

h1 {
    font-size: 42px;
    font-family: 'Roboto Mono', monospace;
    margin: 0;
}

.animated-title {
    font-size: 24px;
    font-family: 'Roboto Mono', monospace;
    white-space: 'pre-line';
    float: left;
    display: block;
}

h3 {
    font-size: 20px;
    font-family: 'Roboto Mono', monospace;
    letter-spacing: 2px;
}

.my-box {
    display: grid;
    gap: 20px;
    grid-template-rows: auto auto auto;
    grid-template-columns: auto auto auto;
    padding-bottom: 100px;
}

.hello-box {
    padding: 10px;
    text-align: center;
}

.hello-box h1 {
    text-align: left;
}

.hello-box h2 {
    text-align: left;
}

.hello-box p {
    font-size: 38px;
}

.topnav {
    overflow: hidden;
}
  
.topnav a {
float: left;
color: #FFFFFF;
text-align: center;
padding: 10px 16px;
text-decoration: none;
font-size: 17px;
border-radius: 40px;
}

.topnav a:hover {
background-color: #FFFFFF;
color: #2C2C2C;
}

.activeLink {
background-color: #02942F;
color: #FFFFFF;
}

.home-div {
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.my-button {
    padding: 14px 18px;
    border-radius: 30px;
    border: none;
    text-decoration: none;
    font-size: 20px;
    color: #FFFFFF;
    background-color: #02942F;
    width: 200px;
    height: 60px;
}

.about-info {
    max-width: 1000px;
    margin: auto;
    margin-top: 100px;
    text-align: left;
}

.about-info img {
    width: 249px;
    height: 320px;
    border-radius: 50%;
    float: left;
    margin: 20px;
}

.contact-form {
    width: 500px;
    margin: 0 auto;
    margin-top: 100px;
}

.form-group {
    margin-bottom: 20px; /* Add some space between each form entry */
}

label {
    display: block; /* Put each label on its own line */
    text-align: left;
    padding-left: 8px;
}

input[type="text"],
input[type="email"],
textarea,
select {
    width: 100%; /* Make text input and textarea fill their container */
    padding: 8px; /* Add some padding for better appearance */
    box-sizing: border-box; /* Include padding and border in the element's total width */
    font-size: 16px;
    border: 1px solid #02942F; /* Default border color */
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus,
select:focus {
    border-color: #02942F; /* Change border color when input is focused */
}

textarea {
    height: 150px; /* Set the default height of the textarea */
    resize: vertical; /* Allow vertical resizing only */
}

input[type="submit"]{
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 30px;
    border: none;
    font-size: 20px;
    color: #FFFFFF;
    background-color: #02942F;
    width: 100px;
    height: 45px;
}

.form-message {
    text-align: center;
    font-size: 42px;
    font-family: 'Roboto Mono', monospace;
    margin: 0;
}

.packages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.package-1,
.package-2,
.package-3 {
    border: none;
    border: 2px solid #02942F;
    padding: 20px;
    border-radius: 40px;
    margin: 10px;
    text-align: center;
    width: 500px;
}

.meeting-link {
    text-decoration: none;
    color: #02942F;
    font-weight: bold;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48;
  size: 200px;
}

.my-footer {
    padding: 8px;
    text-align: center;
    display: grid;
    grid-template-columns: auto auto auto auto;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.my-footer a {
    margin: auto;
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 768px) {
    .my-box {
        grid-template-columns: 1fr;
    }

    .packages {
        flex-direction: column;
        align-items: center;
    }

    .about-info {
        max-width: 700px;
        margin: auto;
        text-align: left;
    }
}

/* Keyframes for animations */
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    33% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.error {
    width: 500px;
    font-size: 18px;
    margin: auto;
    color: #e50000;
}

.success {
    width: 500px;
    font-size: 18px;
    margin: auto;
    margin-top: 250px;
    color: #02942F;
}

.success h2 {
    color: #02942F;
}
